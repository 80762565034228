<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      yachtInfo: null,
    };
  },
  methods: {},
};
</script>

<style scoped></style>
