<!-- layouts/PrintLayout.vue -->
<template>
  <div class="print-layout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PrintLayout",
  mounted() {
    // Remove any existing scroll event listeners
    window.onscroll = null;
  },
};
</script>

<style scoped>
@media print {
  .print-layout {
    padding: 0px;
  }
}

@media screen {
  .print-layout {
    margin: 0 auto;
    padding: 0px;
  }
}
</style>
