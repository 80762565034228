<script>
import SnippetHead from "@/components/SnippetHead.vue";
import SnippetHeader from "@/components/SnippetHeader.vue";
import SnippetFooter from "@/components/SnippetFooter.vue";
import SnippetTopBar from "@/components/SnippetTopBar.vue";
import Modal from "@/components/SnippetModal";
// import { ArrowUpIcon } from "vue-feather-icons";

export default {
  components: {
    SnippetHead,
    SnippetHeader,
    SnippetFooter,
    SnippetTopBar,
    Modal,
    // ArrowUpIcon,
  },
  data() {
    return {
      isModalVisible: false,
      modalArguments: null,
      yachtInfo: null,
    };
  },
  methods: {
    showModal(event) {
      this.modalArguments = event;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },

  mounted() {
    this.eventBus.on("showModal", (event) => {
      this.showModal(event);
    });
    this.eventBus.on("closeModal", (args) => {
      this.closeModal(args);
    });
  },
};
</script>
<template>
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <SnippetHead></SnippetHead>
      <link rel="preconnect" href="https://production.yachtfull.com" />

      <link
        rel="preconnect"
        href="https://static-photos.ams3.digitaloceanspaces.com"
      />
      <link rel="preconnect" href="https://www.google-analytics.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link rel="dns-prefetch" href="https://production.yachtfull.com" />
      <link
        rel="dns-prefetch"
        href="https://static-photos.ams3.digitaloceanspaces.com"
      />

      <link rel="dns-prefetch" href="https://www.google-analytics.com" />
      <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
    </head>

    <body>
      <SnippetTopBar></SnippetTopBar>
      <!-- Navbar Start -->
      <header id="topnav" class="sticky bg-white">
        <SnippetHeader></SnippetHeader>
      </header>
      <!-- Navbar End -->

      <router-view></router-view>

      <Modal
        v-if="isModalVisible"
        :modalArguments="modalArguments"
        :yachtInfo="yachtInfo"
        @close="closeModal"
      />
      <!-- Footer Start -->
      <footer class="footer">
        <SnippetFooter></SnippetFooter>
      </footer>
      <!--end footer-->
      <!-- Back to top -->
      <a href="javascript: void(0);" class="" id="back-to-top">
        <!--        <arrow-up-icon class="icons"></arrow-up-icon>-->
      </a>
      <!-- Back to top -->
      <!-- Footer End -->
    </body>
  </html>
</template>
