<template>
  <section class="section">
    <div class="container mt-60">
      <div class="row">
        <div
          class="col-lg-7 col-md-7 col-12 mb-2 pb-2"
          :class="!imageLeft ? 'order-0 pl-0 pr-5' : 'order-2 pr-0 pl-5'"
        >
          <div class="section-title">
            <h4
              class="title mb-4"
              :class="imageLeft ? 'pl-0 order-2' : 'pr-0 order-0'"
            >
              <router-link
                class="h4"
                :to="{ name: 'yachts', params: { brands: searchString } }"
                >{{ title }}</router-link
              >
            </h4>
            <p
              class="text-100 mb-5"
              :class="imageLeft ? '' : ''"
              v-html="description"
            ></p>
            <router-link
              v-if="this.$route.path === '/newyachts'"
              :to="{ name: 'yachts', params: { brands: searchString } }"
              >{{ $t("new-yachts.see_all_models") }} {{ title }}</router-link
            >
          </div>
          <div :class="imageLeft !== '' ? 'pl-0 order-2' : 'pr-0 order-0'">
            <SnippetContactDetails
              v-if="langLocationID"
              :langLocationID="langLocationID"
            ></SnippetContactDetails>
          </div>
        </div>
        <div
          class="col-lg-5 col-md-5 col-12 mb-4 pb-2"
          :class="imageLeft ? 'order-0' : 'order-2'"
        >
          <div v-if="carouselUrl">
            <video-background
              muted
              loop
              class="video-player"
              poster="images/site-content/about-us/history.jpg"
              src="https://static-photos.ams3.digitaloceanspaces.com/impression-videos/Elburg.mp4"
            >
            </video-background>
          </div>
          <div v-if="!carouselUrl">
            <img
              :src="`${imageurl}`"
              class="img-fluid rounded-md mx-0 d-block picture-partner"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SnippetContactDetails from "@/components/SnippetContactDetails";
export default {
  data() {
    return {
      preHeight: true,
      searchString: this.$route.params.brands,
    };
  },
  components: {
    SnippetContactDetails,
  },
  props: {
    place: {
      type: String,
    },
    langLocationID: {
      type: Number,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    imageLeft: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
    },
    phone: {
      type: String,
    },
    whatsapp: {
      type: String,
    },
    imageurl: {
      type: String,
    },
    carouselUrl: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.column {
  float: left;
  aspect-ratio: auto 16 / 9;
  background-size: contain;
}
.picture-partner {
  /*aspect-ratio: 16 / 9;*/
  width: 100%;
  height: auto;
}
.video-player {
  max-height: 50vh;
  height: 100vh;
}
.pl-5 {
  padding-left: 80px;
}
.pr-5 {
  padding-right: 80px;
}
a {
  color: #0b0f14;
}
</style>
