<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>
import CustomLayout from "@/layouts/CustomLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import PrintLayout from "@/layouts/PrintLayout.vue";

export default {
  components: {
    CustomLayout,
    DefaultLayout,
    PrintLayout,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === "custom") {
        return CustomLayout;
      } else if (this.$route.meta.layout === "print") {
        return PrintLayout;
      } else {
        return DefaultLayout;
      }
    },
  },
};
</script>
