<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <button
            type="button"
            class="btn-close text-400"
            @click="closeModal"
            aria-label="Close modal"
          >
            X
          </button>
        </header>
        <section class="modal-body" id="modalDescription">
          <slot name="body" v-if="this.modalArguments.event === 'elburg'">
            <div class="google-maps">
              <iframe
                src="https://maps.google.com/maps?&amp;hl=nl&amp;q=J.P.%20Broekhovenstraat%2037b,%20Elburg+YachtFull%20International%20Yachtbrokers&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                width="600"
                height="100%"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </slot>
          <slot name="body" v-if="this.modalArguments.event === 'livorno'">
            <div class="google-maps">
              <iframe
                src="https://maps.google.com/maps?&amp;hl=nl&amp;q=Marina%20di%20Cecina%2057023%20Cecina+(YachtFull%20Italy)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                width="600"
                height="100%"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </slot>
          <slot name="body" v-if="this.modalArguments.event === 'brochure'">
            <div class="google-maps">
              <div class="content m-5">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <H5 class="text-300"
                      >{{ $t("brokerage.request_brochure_for") }}
                      {{ this.modalArguments.properties.boatName }}</H5
                    >
                    <div class="row">
                      <div class="col-8">
                        <p
                          class="text-300 pt-2"
                          v-html="$t('brokerage.modals.brochure.description')"
                        ></p>
                        <blockquote class="blockquote mt-3 p-3">
                          <p
                            class="text-muted mb-0 fst-italic"
                            v-if="
                              this.modalArguments.properties.salesPitch
                                .extensiveDescription
                            "
                          >
                            "
                            {{
                              this.modalArguments.properties.salesPitch
                                .extensiveDescription
                            }}
                            "
                          </p>
                        </blockquote>
                      </div>
                      <div class="col-4">
                        <img
                          v-if="this.modalArguments?.media?.formats?.small?.url"
                          class="thumbFormat fa-pull-right"
                          v-lazy="{
                            src: this.modalArguments.media.formats.small.url,
                            delay: 200,
                          }"
                        />
                        <img
                          v-else-if="this.modalArguments.media === null"
                          class="thumbFormat fa-pull-right"
                          src="@/assets/images/site-content/placeholders/not_available.jpg"
                        />
                        <img
                          v-else
                          class="thumbFormat"
                          src="@/assets/images/site-content/placeholders/not_available.jpg"
                        />
                      </div>
                    </div>
                    <!--                    <pre>{{ this.modalArguments.media }}</pre>-->
                  </div>
                  <div class="col-12 col-md-12 mt-2">
                    <ContactForm
                      :yachtid="this.modalArguments.properties.id"
                      :yacht="this.modalArguments.properties"
                      requesttype="brochure"
                    ></ContactForm>
                  </div>
                </div>

                <!--                <div class="text-md-right text-center">-->
                <!--                  <a @click="$bus.$emit('closeModal', true)">{{-->
                <!--                    $t("brokerage.details.modal.close_window")-->
                <!--                  }}</a>-->
                <!--                </div>-->
              </div>
            </div>
          </slot>
          <slot name="body" v-if="this.modalArguments.event === 'viewing'">
            <div class="google-maps">
              <div class="content m-5">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <H5 class="text-300"
                      >{{ $t("brokerage.schedule_viewing_title") }}
                      {{ this.modalArguments.properties.boatName }}</H5
                    >
                    <div class="row">
                      <div class="col-8">
                        <p
                          class="text-300 pt-2"
                          v-html="$t('brokerage.modals.viewing.description')"
                        ></p>
                        <blockquote class="blockquote mt-3 p-3">
                          <p class="text-muted mb-0 fst-italic">
                            Location of vessel:
                          </p>
                          <div
                            v-if="
                              this.modalArguments.properties.Generic
                                .location !== null &&
                              this.modalArguments.properties.Generic
                                .location !== ''
                            "
                          >
                            {{
                              this.modalArguments.properties.Generic.location
                            }}
                          </div>

                          <div v-else>To be determined.</div>
                        </blockquote>
                      </div>
                      <div class="col-4">
                        <img
                          v-if="this.modalArguments?.media?.formats?.small?.url"
                          class="thumbFormat fa-pull-right"
                          v-lazy="{
                            src: this.modalArguments.media.formats.small.url,
                            delay: 200,
                          }"
                        />
                        <img
                          v-else-if="this.modalArguments.media === null"
                          class="thumbFormat fa-pull-right"
                          src="@/assets/images/site-content/placeholders/not_available.jpg"
                        />
                        <img
                          v-else
                          class="thumbFormat"
                          src="@/assets/images/site-content/placeholders/not_available.jpg"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-12 mt-2">
                    <ContactForm
                      :yachtid="this.modalArguments.properties.id"
                      :yacht="this.modalArguments.properties"
                      requesttype="viewing"
                    ></ContactForm>
                  </div>
                </div>
              </div>
            </div>
          </slot>
          <slot name="body" v-if="this.modalArguments.event === 'vr'">
            <div class="vr-container">
              <div class="vr-wrapper">

              <iframe
                :src="getVRUrl"
                height="100vh"
                width="100vw"
                name="Yachtfull"
                id="360"
                loading="lazy"
                allowfullscreen="true"
                allow="fullscreen; accelerometer; gyroscope; magnetometer; vr;"
              ></iframe>
            </div>
            </div>
          </slot>
          <slot name="body" v-if="this.modalArguments.event === 'video'">
            <vimeo-player
              class="videoWrapper"
              player-height="100vh"
              style="padding: 0"
              :options="{ responsive: true, muted: false }"
              ref="player"
              :video-id="this.modalArguments.properties.videoURL"
              controls="true"
              autoplay="true"
              @stop="stop"
              @ready="onReady"
            />
          </slot>
        </section>

        <footer class="modal-footer">
          <button
            type="button"
            class="btn-bottom"
            @click="closeModal"
            aria-label="Close modal"
          >
            {{ $t("brokerage.details.modal.close_window") }}
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
import ContactForm from "@/components/ContactForm";

export default {
  name: "SnippetModals",
  components: { ContactForm },
  data() {
    return {
      playerReady: false,
      baseVRUrl: 'https://yachtfull.zeeland360.nl/'
    };
  },
  computed: {
    getVRUrl() {
      const input = this.modalArguments.properties;

      // If it's a number, append to base URL
      if (!isNaN(input) && !isNaN(parseFloat(input))) {
        return `${this.baseVRUrl}${input}`;
      }

      // If it's not a number, use it as a direct URL
      return input;
    }
  },
  methods: {
    closeModal($event) {
      if (this.modalArguments.event === "video") {
        this.stop();
      }
      this.eventBus.emit("closeModal", $event);
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
    },
    stop() {
      this.$refs.player.pause();
    },
  },
  props: {
    modalArguments: {
      type: Array,
    },
    yachtInfo: {
      type: Array,
    },
  },
};
</script>
<style scoped>
.thumbFormat {
  width: 80%;
  aspect-ratio: 4 / 3;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
}
.google-maps {
  position: relative;
  overflow: hidden;
  width: 85%;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
}
.google-maps iframe,
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  display: block;
  border: solid 40px #ffffff;
}
.vr {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 30px;
  background-color: transparent;
  opacity: 1;
}
.vr iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 90vw;
  height: 90vh;
  background-color: transparent;
  opacity: 1;
  z-index: 1000;
  border-radius: 14px;
  display: block;
  border-style: none;
}

.vr-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.vr-wrapper {
  width: 90vw;
  height: 90vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vr-wrapper iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 14px;
  background-color: transparent;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 1.5s ease;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff3cd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  /*background-color: #06173e;*/
  opacity: 1;
  background: url("../assets/images/site-content/background_brochure.jpg")
    #06173f;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  margin: auto;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 5px;
  border: none;
  display: flex;
}

.modal-header {
  position: relative;
  justify-content: space-between;
}

.modal-footer {
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 0;
  opacity: 1;
  z-index: 1000;
}

.btn-close {
  position: absolute;
  top: 5px;
  z-index: 1500;
  left: 0px;
  border: none;
  border-radius: 8px;
  font-size: 2em;
  font-weight: bolder;
  cursor: pointer;
  color: #000;
  background: #fff;
}

.btn-bottom {
  border: 1px solid #efefef;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
}
.modal-header .btn-close {
  margin: 20px;
  background-color: white;
  opacity: 1;
  border: 2px solid #000;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-top: 0px;
  padding-right: 5px;
}
</style>
